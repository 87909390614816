<template>
  <Box
    :key="key"
    class="side-nav"
    :class="sideNavClasses"
    @mouseover="showCollapseButton(true)"
    @mouseleave="showCollapseButton(false), toggleHovered(false)"
  >
    <Box flex height="40px" v-bind="logoBoxProps">
      <CompanyLogo />
      <ButtonIcon v-if="collapseButtonVisible" variant="outlined" :icon="collapsedIcon" shape="circle" class="collapse-icon" @click.stop="onToggle()" />
    </Box>
    <Box class="side-nav-inner" :class="sideNavInnerClasses" @mouseover="toggleHovered(true)">
      <Box v-if="isLoggedIn" flex col space="6" height="100%">
        <Box flex justify="center" alignItems="center" height="36px" @click="toggleCollapsed(false)">
          <Icon v-if="collapsed" icon="search" size="md" color="textSecondary" />
          <GlobalSearch v-else />
        </Box>
        <LassoMenu class="side-nav-menu" />
        <CardUser />
      </Box>
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Box, ButtonIcon, Icon } from '@lasso/luikit'
import { useSidenav } from '@sidenav/hooks/useSidenav'
import { GlobalSearch } from '@sidenav/components/GlobalSearch'
import { CompanyLogo } from '@sidenav/components/CompanyLogo'
import { LassoMenu } from '@sidenav/components/LassoMenu'
import { CardUser } from '@sidenav/components/CardUser'
import { useUser } from '@lasso/shared/hooks'
import { ComponentProps } from '@lasso/shared/types'

const {
  collapseButtonVisible,
  collapsedInternal,
  collapsed,
  toggleHovered,
  toggleCollapsed,
  showCollapseButton,
} = useSidenav()
const { isLoggedIn, userInfo } = useUser()

const collapsedIcon = computed(() => collapsedInternal.value ? 'chevron_right' : 'chevron_left')

const logoBoxProps = computed<Partial<ComponentProps<typeof Box>>>(() => ({
  justify: collapsed.value ? 'center' : 'start',
  alignItems: 'center',
}))

const sideNavClasses = computed(() => ({
  'side-nav-expanded-view': !collapsed.value,
  'side-nav-collapsed-view': collapsed.value,
}))

const sideNavInnerClasses = computed(() => ({
  'side-nav-inner-expanded-view': !collapsed.value,
  'side-nav-inner-collapsed-view': collapsed.value,
}))

const key = computed(() => userInfo.value?.id ?? '')

const onToggle = () => {
  toggleCollapsed()
  toggleHovered(false)
}
</script>

<style scoped>
.side-nav {
  @apply flex flex-col transition-all duration-300 gap-6 mr-[var(--global-layout-padding)];
  width: var(--sidenav-width);
}

@keyframes transition-overflow {
  from { overflow: hidden }
}

.side-nav-expanded-view .side-nav-inner {
  width: var(--sidenav-width);
  animation: transition-overflow 300ms;
}

.side-nav-collapsed-view {
  @apply w-[var(--sidenav-collapsed-width)];
}

.side-nav-inner {
  @apply flex flex-col justify-between gap-4 bg-base-100 border;
  @apply transition-all duration-300;

  /* center/middle part of sidenav is scrollable */
  height: calc(100vh - 40px - 1.5rem - (var(--global-layout-padding) * 2));
  border-radius: 12px 12px 12px 64px;
}

.side-nav-inner-expanded-view {
  @apply px-6 py-8;
}

.side-nav-inner-collapsed-view{
  @apply px-4 py-8;
}

.side-nav-menu {
  @apply flex-grow overflow-auto;
}

.collapse-icon {
  @apply absolute top-3 bg-base-100;
  right: calc(var(--global-layout-padding) - 7px);
}
</style>
