<template>
  <Box flex spaceX="4" :justify="collapsed ? 'center' : 'start'">
    <Box>
      <CircularProgress v-if="loading" size="2xl" color="primary" />
      <Tippy v-else px="0" py="0" offsetDistance="24" placement="top-end" @show="toggleCollapsed(false)">
        <Indicator v-if="isImpersonating" color="base" size="sm">
          <template #indicatorItem="{ classes }">
            <Avatar :src="indicatorUrl" alt="impersonating" :class="classes" rounded size="xxs" textColor="textSecondary" />
          </template>
          <Avatar :alt="name.fullName" clickable rounded size="xs" textColor="textSecondary" />
        </Indicator>
        <Avatar v-else :alt="name.fullName" clickable rounded size="xs" textColor="textSecondary" />

        <template #content="{ hide }">
          <List disablePX color="base" textColor="textSecondary">
            <ListItemButton @click="onAccountSettings(hide)">
              <Typography variant="body2">
                Account Settings
              </Typography>
            </ListItemButton>
          </List>
        </template>
      </Tippy>
    </Box>

    <Box v-if="!collapsed" flex direction="col" justify="center">
      <Typography v-if="loading" variant="body2" color="textSecondary">
        Loading...
      </Typography>

      <Tooltip v-else data-test-id="username" placement="top" minWidth="80px">
        <Typography variant="body2" truncateRow="one" color="textSecondary" wordBreak="anywhere">
          {{ name.firstName }}
        </Typography>
        <template #content>
          <Box flex direction="col">
            <Typography variant="body1" color="textSecondary">
              {{ name.fullName }}
            </Typography>
            <Typography variant="caption1" color="textSecondary">
              {{ roles }}
            </Typography>
          </Box>
        </template>
      </Tooltip>

      <Typography variant="caption1" color="primary">
        <Link v-if="!isLoggedIn" hoverUnderline color="secondary" @click="onLogin()">
          Sign In
        </Link>
        <Link v-else-if="isImpersonating" data-test-id="impersonating" hoverUnderline color="secondary" @click="onRevertImpersonation()">
          Revert
        </Link>
        <Link v-else hoverUnderline color="secondary" @click="onLogout()">
          Sign Out
        </Link>
      </Typography>
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Avatar, Box, CircularProgress, Indicator, Link, List, ListItemButton, Tippy, Tooltip, Typography } from '@lasso/luikit'
import { navigateToAppUrl, urlPublicAsset } from '@lasso/shared/utils'
import { useGoogleTagManager, useToast, useUser } from '@lasso/shared/hooks'
import { generateGtmFixture } from '@sidenav/utils/gtm'
import { useSidenav } from '@sidenav/hooks/useSidenav'
import { useAuth } from '@lasso/shared-auth'

const { revertImpersonation, loading, logout } = useAuth()
const googleTagManager = useGoogleTagManager()
const { collapsed, toggleCollapsed } = useSidenav()
const { userInfo, authInfo, isLoggedIn } = useUser()
const toast = useToast()

const isImpersonating = computed(() => authInfo.value?.impersonate ?? false)
const indicatorUrl = computed(() => isImpersonating.value ? urlPublicAsset('images/ImpersonationAvatar.png') : '')

const name = computed(() => {
  const { firstName = '', lastName = '' } = userInfo.value ?? {}

  return {
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
  }
})

const roles = computed(() => (authInfo.value?.userRoles ?? []).join(', '))

const onRevertImpersonation = async () => {
  const result = await revertImpersonation()

  if (!result.ok && result.error.details) {
    toast.error('Failed to revert impersonation', result.error.details.message)
  }
}

const onAccountSettings = (hide: () => void) => {
  hide()
  navigateToAppUrl('app/auth/account-settings')
}

const onLogout = () => {
  googleTagManager.triggerEvent(generateGtmFixture({ name: 'sign-out', el: 'link' }))
  logout()
}

const onLogin = () => {
  navigateToAppUrl('app/auth')
}
</script>
